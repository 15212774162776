import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { TwitterShareButton } from "react-share";
import { EViewSize } from "@enums";
import {
  isEmpty,
  truncateAddress,
  truncateDecimal,
  formatNumberToUSD,
} from "../../../utils/wallet/numberFormet.util";
import Image from "next/image";
import ToastComponent from "@secondary/toast";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SelectDropdown from "src/components/secondary-wallet/Select-dropdown";
import {
  ISupportedCurrenciesList,
  IEstamateTaxItems,
  UsegetUSDPrice,
} from "@utils/wallet/wallet";
// import Checkbox from "@mui/material/Checkbox";
import { SecondaryButton } from "src/components/secondary-wallet/secondaryButton";
import { palette } from "@styles/theme";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { CheckNetworkTokens, OnSwitchNetwork } from "@utils";
import { Loader } from "@shared/loader";
import { IMojitoProfileUserOrg } from "@interfaces";
import { INotificationByType, notificationsByType } from "@hooks";
import { MediaImageComponent } from "@layouts/profile/wallet/components/MediaImageComponet";
import { useNetwork } from "wagmi";
import { Checkbox } from "./buyNowMakeOfferModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export interface errorModalOpen {
  open: boolean;
  msg: string;
}
interface currencyProps {
  contractAddress: string;
  currency: string;
  id: string;
  marketPlaceContractAddress: string;
}

export interface INFTListData {
  imgUrl: string;
  animatedUrl: string;
  networkId: string;
  networKTokenType: string;
  contractAddress: string;
  id: string;
  nftTokenId: string;
  network: string;
  currency: string;
  networkTokenType: string;
  tokenId: string;
  artistName: string;
  tokenType: string;
  name: string;
  year: string;
  editionNumber: string;
  tokenURI: string;
  mediaExtension: string;
  mediaImage: string;
}

interface NftSaleModel {
  modelopen: boolean;
  organization: any;
  handleClose: () => void;
  handleClick: (
    val: string,
    btnName: string,
    selectedCurrency?: currencyProps
  ) => void;
  toastErrorModalClose: () => void;
  isEdit: boolean;
  type: string;
  priceVal: any;
  nftData: INFTListData;
  toastErrorModalOpen: errorModalOpen;
  buttonLoader: any;
  listingFlowMsg: string;
  isValidNetwork?: any;
  twitterUrl?: any;
  supportedCurrencies: ISupportedCurrenciesList[] | null;
  estimateTax: IEstamateTaxItems | null;
}

interface EModel {
  modelopen: boolean;
  enquiryData?: any;
  handleClose?: any;
  handleClick?: any;
  message?: string;
  handleRetryPayment?: () => void;
  isBuyNowOrMakeOffer?: boolean;
  isSwitchNetwork?: boolean;
  isAcceptOffer?: boolean | undefined;
}

interface IfeesValue {
  usd?: any;
  crypto?: any;
  usdName?: string;
  cryptoName?: string;
}

interface IFees {
  platform_fee: IfeesValue;
  creator_fee: IfeesValue;
  total_revenue: IfeesValue;
}

interface IModel {
  modelopen: boolean;
  ErrorMsg?: string;
}
interface MetaMaskModal {
  modalOpen: boolean;
  handleMetaMaskClick: (name: string) => void;
}
interface primaryWalletTransferModal {
  modalOpen: boolean;
  handleTransferClick: (name: string) => void;
  onChangeTransferAddress: (val: string) => void;
  walletValue: string;
  modalControl: string;
}

const style = {
  position: "absolute",
  left: "50%",
  top: "6%",
  transform: "translate(-50%, 45%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, 12%)",
    width: "90%",
  },
  width: 456,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};
const transferModalStyle = {
  position: "absolute",
  left: "50%",
  top: "6%",
  transform: "translate(-50%, 45%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, -6%)",
    width: "100%",
    height: "100%",
    padding: "20px 20px",
  },
  width: 552,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "50px 60px",
};
const metaMaskStyle = {
  position: "absolute",
  left: "50%",
  top: "6%",
  transform: "translate(-50%, 45%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, -6%)",
    width: "100%",
    height: "100%",
    padding: "20px 20px",
  },
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "50px 60px",
};

const errorpopupclass = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 20%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, 0%)",
    width: "100%",
    height: "100%",
    p: 4,
  },
  height: "auto",
  width: "580px",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "64px",
};

const ConnectBtn = styled.div`
  padding: 16px 10px;
  letter-space: 10px;
  width: 224px;
  height: 54px;
  background: linear-gradient(0deg, rgba(66, 0, 96, 0.4), rgba(66, 0, 96, 0.4)),
    linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
  border-radius: 4px;
  cursor: pointer;
  color: #ffff;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;
export const PrimayTransferWalletModal = styled(
  ({
    modalOpen,
    handleTransferClick,
    onChangeTransferAddress,
    walletValue,
    modalControl,
    ...props
  }: primaryWalletTransferModal) => {
    return (
      <div>
        <Modal
          {...props}
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 99999999,
            backgroundColor: "gba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}>
          <Box sx={transferModalStyle}>
            <Box className="close">
              <IconButton
                onClick={() => handleTransferClick("cancel")}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>
            <Box className={"overall-pad"}>
              <Typography align="center" className="heading">
                Transfer NFT
              </Typography>
              {modalControl === "transfer" ? (
                <>
                  <Typography className="content" align="center">
                    Enter the wallet address that will receive this NFT
                  </Typography>
                  <Box className="wallet-address">
                    <Typography className="wallet-address-name">
                      WALLET ADDRESS
                    </Typography>
                    <Typography>
                      <input
                        className="wallet-address-input"
                        type={"text"}
                        placeholder={"0x00...0000"}
                        value={walletValue}
                        onChange={(e) =>
                          onChangeTransferAddress(e.target.value)
                        }
                      />
                    </Typography>
                  </Box>
                  <Box className={"meta-mask-adjus"}>
                    <SecondaryButton
                      onClick={() => handleTransferClick("send")}
                      buttontype="primary"
                      disabled={!/^0x[0-9a-fA-F]{40}$/.test(walletValue)}>
                      {"Send"}
                    </SecondaryButton>
                  </Box>
                </>
              ) : (
                <Grid item xs={12} sx={{ marginTop: "30px" }}>
                  <ModalButton
                    onClick={() => handleTransferClick("cancel")}
                    className={
                      modalControl === "success"
                        ? "success-transfer"
                        : "error-transfer"
                    }>
                    {modalControl === "success" ? (
                      <>
                        <Box>Success!</Box>
                        <Box>
                          Item has been sent. Please allow up to 15minutes.
                        </Box>
                      </>
                    ) : (
                      <Box>Something went wrong. please try again!</Box>
                    )}
                  </ModalButton>
                  <Grid item xs={12}>
                    <ModalButton
                      className="success-button"
                      onClick={() => handleTransferClick("redirect")}>
                      View on Sotheby’s Metaverse
                    </ModalButton>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              )}
            </Box>

            {modalControl === "transfer" && walletValue?.length !== 0 && (
              <Grid item xs={12}>
                <Box className={"acc-st-transfer"}>
                  <Grid item xs={12} className={"acc-st-msg"}>
                    <Grid item xs={12} className={"acc-st-text"}>
                      <span className={"icon-align"}>
                        <SVG src="/icons/warning.svg" />
                      </span>
                      <span className={"acc-link"}>
                        <span className="acc-st">
                          This cannot be undone. Please make sure the wallet
                          address is correct
                        </span>
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}

            <Typography
              align="center"
              className="cancel-metamask"
              onClick={() => handleTransferClick("cancel")}>
              Cancel
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .overall-pad {
    padding: 0px 26px;
  }
  .acc-st-transfer {
    margin-top: 16px;
    display: flex;
    padding: 8px 0px;
    border-radius: 4px;
    .acc-st-msg {
      display: inline-block;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      .acc-link {
        cursor: pointer;
        font-weight: 400;
      }
      .acc-st {
        font-weight: 400;
      }
    }
    .acc-st-text {
      display: inline-block;
      background: #1c7670;
      padding: 8px;
      .icon-align {
        padding-right: 10px;
      }
    }
  }
  .success-button {
    background: ${palette.white};
    border: 1px solid rgba(4, 36, 57, 0.3);
    color: ${palette.navyBlue};
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    margin-top: 16px;
  }
  .error-transfer {
    border: 1px solid #cd564f;
    background: #cd564f;
    padding: 16px 10px;
    height: auto;
    border-radius: 8px;
    justify-content: center;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #000000;
  }
  .success-transfer {
    border: 1px solid #15812c;
    background: #eafee9;
    padding: 16px 10px;
    height: auto;
    border-radius: 8px;
    justify-content: center;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.1px;
    color: #000000;
  }

  .wallet-address {
    background: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    margin-top: 24px;
    padding: 16px;
    .wallet-address-name {
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      color: ${palette.black};
      padding-bottom: 8px;
    }
    .wallet-address-input {
      width: 100%;
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${palette.black};
      border: none;
      outline: none;
      border-bottom: 1px solid ${palette.gray};
    }
    .wallet-address-input:focus-visible {
      outline: none;
    }
  }
  .icon-style {
    margin-right: 10px;
  }
  .cancel-metamask {
    cursor: pointer;
    padding: 12px 24px;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #000000;
    margin-top: 20px;
  }
  .wallet-connect-adjus {
    padding-top: 16px;
    padding-bottom: 24px;
  }
  .meta-mask-adjus {
    padding-top: 20px;
  }
  .btn-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 10px;
    letter-space: 10px;
    width: 380px;
    height: 56px;
    background: linear-gradient(
        0deg,
        rgba(66, 0, 96, 0.4),
        rgba(66, 0, 96, 0.4)
      ),
      linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
    border-radius: 4px;
    cursor: pointer;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #f8fafc;
  }
  .heading {
    font-family: "Mercury Text G1";
    font-style: normal;
    font-weight: 325;
    font-size: 24px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 16px;
  }
  .content {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
  }
  .close {
    display: none;
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .heading {
      padding-bottom: 32px;
    }
    .meta-mask-adjus {
      padding-top: 32px;
    }
    .btn-wallet {
      width: 100%;
    }
    .close {
      display: flex;
      justify-content: end;
      padding-bottom: 29px;
    }
  }
`;

export const MetaMaskModal = styled(
  ({ modalOpen, handleMetaMaskClick, ...props }: MetaMaskModal) => {
    return (
      <div>
        <Modal
          {...props}
          open={modalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 99999999,
            backgroundColor: "gba(0, 0, 0, 0.5)",
            backdropFilter: "blur(4px)",
          }}>
          <Box sx={metaMaskStyle}>
            <Box className="close">
              <IconButton
                onClick={() => handleMetaMaskClick("Cancel")}
                aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography align="center" className="heading">
              Connect Wallet
            </Typography>
            <Typography className="content" align="center">
              Select how you would like to connect your wallet
            </Typography>

            <Box className={"meta-mask-adjus"}>
              <SecondaryButton
                onClick={() => handleMetaMaskClick("MetaMask")}
                buttontype="primary">
                <SVG
                  src={"/icons/metamask.svg"}
                  width={25}
                  height={25}
                  className="icon-style"
                />
                {"Metamask"}
              </SecondaryButton>
            </Box>
            <Box className={"wallet-connect-adjus"}>
              <SecondaryButton
                onClick={() => handleMetaMaskClick("WalletConnect")}
                buttontype="primary">
                <SVG
                  className="icon-style"
                  src={"/icons/WalletConnect-icon.svg"}
                  width={25}
                  height={25}
                />
                {"WalletConnect"}
              </SecondaryButton>
            </Box>
            <Typography
              align="center"
              className="cancel-metamask"
              onClick={() => handleMetaMaskClick("Cancel")}>
              Cancel
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .icon-style {
    margin-right: 10px;
  }
  .cancel-metamask {
    cursor: pointer;
    padding: 12px 24px;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #000000;
  }
  .wallet-connect-adjus {
    padding-top: 16px;
    padding-bottom: 24px;
  }
  .meta-mask-adjus {
    padding-top: 20px;
  }
  .btn-wallet {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 10px;
    letter-space: 10px;
    width: 380px;
    height: 56px;
    background: linear-gradient(
        0deg,
        rgba(66, 0, 96, 0.4),
        rgba(66, 0, 96, 0.4)
      ),
      linear-gradient(301.04deg, #55ffbc -66.81%, #ba71db 90.61%);
    border-radius: 4px;
    cursor: pointer;
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #f8fafc;
  }
  .heading {
    font-family: "Mercury Text G1";
    font-style: normal;
    font-weight: 325;
    font-size: 24px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 16px;
  }
  .content {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
  }
  .close {
    display: none;
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .heading {
      padding-bottom: 32px;
    }
    .meta-mask-adjus {
      padding-top: 32px;
    }
    .btn-wallet {
      width: 100%;
    }
    .close {
      display: flex;
      justify-content: end;
      padding-bottom: 29px;
    }
  }
`;
export const ConnectWalletErrorModel = styled(
  ({
    modelopen,
    handleClose,
    message,
    handleRetryPayment,
    isBuyNowOrMakeOffer = false,
    isSwitchNetwork = false,
    isAcceptOffer = true,
    ...props
  }: EModel) => {
    return (
      <Modal
        open={modelopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 99999999,
          backgroundColor: "gba(0, 0, 0, 0.5)",
          backdropFilter: "blur(4px)",
        }}
        {...props}>
        <Box
          sx={[
            errorpopupclass,
            {
              height: isBuyNowOrMakeOffer ? "auto" : "auto",
              transform: isBuyNowOrMakeOffer
                ? "translate(-50%, 20%)"
                : "translate(-50%, 40%)",
            },
          ]}>
          <Grid container className="grid-container">
            <Grid
              item
              xs={12}
              className={isBuyNowOrMakeOffer ? "image" : "title"}>
              {isBuyNowOrMakeOffer ? (
                <Box className="image-1">
                  <Image
                    src={"/images/error-sothebys.png"}
                    alt="error"
                    height={"120px"}
                    width={"120px"}
                  />
                </Box>
              ) : (
                <Typography className="title-connection" align="center">
                  Connection Failed
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} className={"grid-content"}>
              <Box className={"content"}>
                <Typography className="content-box">{message}</Typography>
              </Box>
            </Grid>
            {isBuyNowOrMakeOffer ? (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: 4,
                    alignSelf: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}>
                  {isAcceptOffer && (
                    <SecondaryButton
                      buttontype="primary"
                      onClick={handleRetryPayment}>
                      {isSwitchNetwork
                        ? "Retry switch network"
                        : "Retry payment"}
                    </SecondaryButton>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    className="link"
                    onClick={handleClose}>
                    <b>Cancel</b>
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className="link"
                  onClick={handleClose}>
                  <b>Return to Marketplace</b>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Modal>
    );
  }
)`
  .title {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
  .title-connection {
    font-family: Mercury Text G1;
    font-style: normal;
    font-weight: 325;
    font-size: 24px;
    line-height: 18px;
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .content-box {
      width: 100%;
      word-wrap: break-word;
    }
    .grid-container {
      margin-top: 50% !important;
    }
  }
  .content {
    text-align: center;
  }
  .link {
    font-size: 12px;
    text-decoration: underline;
    font-family: BentonSans;
    cursor: pointer;
  }
  .image_exclude {
    display: flex;
    justify-content: flex-end;
  }
  .image {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
  }
  .image-1 {
    width: 120px;
    height: 120px;
  }
  .content-box {
    font-size: 14px;
    padding-bottom: 20px;
    font-family: BentonSans;
    word-wrap: break-word;
    font-weight: 400;
  }
  .grid-container {
    margin-top: 2%;
  }
  .grid-content {
    display: flex;
    justify-content: center;
  }
`;

export const EnquireWalletModel = styled(
  ({ modelopen, enquiryData, handleClose, handleClick, ...props }: EModel) => {
    return (
      <div>
        <Modal
          {...props}
          open={modelopen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: 99999999, backdropFilter: "blur(3px)" }}>
          <Box sx={style}>
            {enquiryData ? (
              <>
                <Typography align="center" className="heading">
                  {String(enquiryData).replace(".", "!")}
                </Typography>
                <Typography className="content" align="center">
                  Your item will be approved or denied via email.
                </Typography>
              </>
            ) : (
              <></>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <ConnectBtn onClick={handleClick}>Okay</ConnectBtn>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .heading {
    font-size: 20px;
    font-weight: 325;
    padding-bottom: 10px;
    font-family: Mercury Text G1;
    text-transform: capitalize;
  }
  .content {
    font-size: 13px;
    padding-bottom: 20px;
    font-family: BentonSans;
  }
`;

const itemsstyle = {
  flexGrow: 1,
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 12%)",
  width: "50%",
  "@media screen and (max-width: 768px)": {
    width: "100%",
    transform: "translate(-50%, 4%)",
    height: "100%",
    overflow: "auto",
  },
  bgcolor: palette.white,
  opacity: 1,
  boxShadow: 100,
  backdropFilter: "blur(3px)",
  borderRadius: "5px",
  p: 4,
  overflow: "hidden auto",
  maxHeight: "calc(100vh - 100px)",
};

const ModalButton = styled.div`
  padding: 16px 10px;
  letter-space: 10px;
  width: 100%;
  height: 54px;
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  cursor: pointer;
  color: #ffff;
  text-align: center;
  font-size: 15px;
`;

const Listoption = (title: string, data: any) => {
  return (
    <>
      <Grid item xs={6}>
        <Typography className={"colorWhite"}>{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={"colorWhite-val revenue-value"} align="right">
          {data}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className="border-line-grid"></div>
      </Grid>
    </>
  );
};

interface IListItem {
  type_JSON: any;
  handleClick: any;
  isEdit: boolean;
  type: string;
  priceVal?: any;
  nftData?: any;
  listingFlowMsg?: string;
  buttonLoader?: any;
  isValidNetwork?: any;
  organization: any;
  supportedCurrencies: ISupportedCurrenciesList[] | null;
  estimateTax: IEstamateTaxItems | null;
  handleSwitchNetwork: () => void;
}

const ListItem = styled(
  ({
    type_JSON,
    handleClick,
    isEdit,
    type,
    priceVal,
    nftData,
    buttonLoader,
    listingFlowMsg,
    isValidNetwork,
    organization,
    supportedCurrencies,
    estimateTax,
    handleSwitchNetwork,
    ...props
  }: IListItem) => {
    const { chain } = useNetwork();
    const inputRef: any = useRef(null);
    const currencyList = supportedCurrencies?.map((item) => {
      return {
        value: item?.id,
        lable: item?.symbol,
        id: item?.contractAddress,
        marketPlaceContractAddress: item?.secondaryMarketplaceContractAddress,
      };
    });
    const PriceList: any = !isEdit
      ? currencyList?.sort((a: any, b: any) =>
          a?.lable?.localeCompare(b?.lable)
        )
      : currencyList?.sort((a: any) =>
          a?.lable === nftData?.currency ? -1 : 1
        );
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [selectedCurrency, setSelectedCurrency] = useState<currencyProps>({
      contractAddress: PriceList?.[0]?.id ?? "",
      currency: PriceList?.[0]?.lable ?? "",
      id: PriceList?.[0]?.value ?? "",
      marketPlaceContractAddress:
        PriceList?.[0]?.marketPlaceContractAddress ?? "",
    });
    useEffect(() => {
      inputRef?.current?.focus();
    }, [selectedCurrency]);
    useEffect(() => {
      if (priceVal && isEdit) {
        const crypto = priceVal?.find((data: any) => data.unit !== "USD");
        if (crypto?.value) {
          onChangeCrypto(truncateDecimal(crypto?.value, 4));
        }
      }
    }, [priceVal, estimateTax]);

    const [usdPrice, setUsdPrice] = useState<IfeesValue>({
      usd: "",
      crypto: "",
    });

    const [feeData, setFeeData] = useState<IFees>({
      platform_fee: { usd: "0", crypto: "0" },
      creator_fee: { usd: "0", crypto: "0" },
      total_revenue: { usd: "0", crypto: "0" },
    });
    const [kycErrVal, setKycErrVal] = useState<boolean>(false);
    const [contactUs, setContactUs] = useState<boolean>(false);

    const { getUSDPrice, onrefectchUSDPrice } = UsegetUSDPrice({
      crypto: selectedCurrency?.currency ?? "",
    });

    const currentNotificationKey = Object.keys(
      organization?.notifications ?? []
    ).find(
      (key) =>
        organization?.notifications?.[
          key as keyof IMojitoProfileUserOrg["notifications"]
        ]
    ) as keyof INotificationByType;

    const userRoleContext = notificationsByType[currentNotificationKey];

    useEffect(() => {
      onrefectchUSDPrice();
    }, [selectedCurrency?.currency]);

    const ETHBID = getUSDPrice?.amount ? +getUSDPrice?.amount : 0.5;

    useEffect(() => {
      if (!isEmpty(usdPrice?.crypto)) onChangeCrypto(usdPrice?.crypto);
    }, [ETHBID]);

    const onChangeCrypto = (val: string) => {
      setUsdPrice({
        ...usdPrice,
        crypto: val,
        usd: truncateDecimal(+val * ETHBID, 4),
      });
      const usdVal: any = truncateDecimal(+val * ETHBID, 4);
      if (usdVal > 10000) {
        if (
          organization?.role === "Basic" ||
          organization?.role === "MissingInformation" ||
          organization?.role === "EndUser" ||
          organization?.role === "TransactionalNoID"
        ) {
          setKycErrVal(true);
          setContactUs(false);
        } else {
          setKycErrVal(false);
          setContactUs(false);
        }
      } else {
        setKycErrVal(false);
        setContactUs(false);
      }
      const CryptoValue = +val;
      const platform_fee_crypto = estimateTax?.platformFee
        ? CryptoValue * (estimateTax?.platformFee / 100)
        : 0;
      const creator_fee_crypto = estimateTax?.royaltyFee
        ? CryptoValue * (estimateTax?.royaltyFee / 100)
        : 0;

      const totalRevenueUsd: any =
        +truncateDecimal(+val * ETHBID, 4) -
        (+truncateDecimal(platform_fee_crypto * ETHBID, 4) +
          +truncateDecimal(creator_fee_crypto * ETHBID, 4));
      const totalRevenueCrypto: any =
        +val -
        (+truncateDecimal(platform_fee_crypto, 4) +
          +truncateDecimal(creator_fee_crypto, 4));
      setFeeData({
        platform_fee: {
          usd: +truncateDecimal(platform_fee_crypto * ETHBID, 4),
          crypto: +truncateDecimal(platform_fee_crypto, 4),
        },
        creator_fee: {
          usd: +truncateDecimal(creator_fee_crypto * ETHBID, 4),
          crypto: +truncateDecimal(creator_fee_crypto, 4),
        },
        total_revenue: {
          usd: +truncateDecimal(totalRevenueUsd, 4),
          crypto: +truncateDecimal(totalRevenueCrypto, 4),
        },
      });
    };
    const dropDownPrice = () => {
      return (
        PriceList &&
        PriceList?.filter(
          (ele: any) => ele?.value !== selectedCurrency?.id
        )?.map((ele: any) => (
          <Typography
            className={" menu-item my-2"}
            key={ele?.value}
            onClick={() => {
              setSelectedCurrency({
                contractAddress: ele?.id,
                currency: ele?.lable,
                id: ele?.value,
                marketPlaceContractAddress: ele?.marketPlaceContractAddress,
              });
            }}>
            {usdPrice?.crypto}
            <span className="currency">{ele?.lable}</span>
          </Typography>
        ))
      );
    };
    return (
      <Grid item xs={12} lg={6} {...props}>
        {/* Text field  inputs*/}
        <Box sx={{ position: "relative" }}>
          <div className={"select-dropdown with-input"}>
            <span className="select-lable">{"PRICE & CURRENCY"}</span>
            <Box sx={{ display: "flex" }}>
              <input
                ref={inputRef}
                type="text"
                value={usdPrice?.crypto}
                className="input-make-offer"
                placeholder="0.00"
                onChange={(e) => {
                  const val = e.target.value.replace(/[^0-9.]/g, "");
                  if (val && val?.length <= 6) {
                    const matcher = new RegExp(`^[0-9]*\\.?[0-9]{0,${4}}$`);
                    if (val === "" || matcher.test(val)) {
                      onChangeCrypto(val);
                    }
                  } else if (val?.length === 0) {
                    onChangeCrypto("");
                  }
                }}
              />
              {PriceList?.length === 1 ? (
                <div className="single-wethaddress">
                  <SelectDropdown
                    selectValue={
                      PriceList?.filter(
                        (ele: any) => ele?.value === selectedCurrency?.id
                      )[0]?.lable
                    }
                    dropDownData={dropDownPrice()}
                    externalcss={"filter-select-dropdown"}
                  />
                </div>
              ) : (
                <SelectDropdown
                  selectValue={
                    PriceList?.filter(
                      (ele: any) => ele?.value === selectedCurrency?.id
                    )[0]?.lable
                  }
                  dropDownData={dropDownPrice()}
                  externalcss={"filter-select-dropdown"}
                />
              )}
            </Box>
          </div>
        </Box>
        <Typography sx={{ marginTop: 10 }} className={"usd-text"}>
          {/* {isEmpty(usdPrice?.usd) ? "0" : usdPrice?.usd}{" "} */}
          {formatNumberToUSD(usdPrice?.usd, 6)} {" USD"}
        </Typography>
        {organization?.w8Form && kycErrVal && (
          <Box className={"error-card"}>
            <Typography>
              Please{" "}
              <b
                onClick={() => {
                  window.open(userRoleContext.link, "_blank");
                }}>
                complete your profile
              </b>{" "}
              to list item more than 10K USD.
            </Typography>
          </Box>
        )}
        {organization?.w8Form && contactUs && (
          <Box className={"error-card"}>
            <Typography>
              There is a problem retrieving your account information{" "}
              <b
                onClick={() => {
                  window.open(userRoleContext.link, "_blank");
                }}>
                Please contact us.
              </b>
            </Typography>
          </Box>
        )}
        <Box className={"errorMsg"}>
          {usdPrice?.crypto > 0 && +usdPrice?.crypto < 0.04 ? (
            `Make listing above 0.04 ${selectedCurrency?.currency || " WETH"}`
          ) : isValidNetwork !== chain?.id ? (
            <>
              Please switch to{" "}
              <span className="switch-network" onClick={handleSwitchNetwork}>
                {nftData?.networkId}
              </span>
            </>
          ) : (
            ""
          )}
        </Box>
        <div
          style={{ marginBottom: "20px" }}
          className="border-line-grid"></div>

        {/*Feel list*/}
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}>
          {Listoption(
            "NETWORK",
            <Box className={"fees-class"}>
              {nftData?.network}
              <Box className="token-type-box">
                <Typography align="center" className="token-type">
                  {nftData?.networkTokenType}
                </Typography>
              </Box>
            </Box>
          )}
          {Listoption(
            `PLATFORM FEE ${estimateTax?.platformFee ?? 0}%`,
            <Box className={"fees-class"}>
              {` ${feeData?.platform_fee?.crypto} ${
                selectedCurrency?.currency || " WETH"
              }`}{" "}
              <span style={{ paddingLeft: "8px" }}>{`${
                feeData?.platform_fee?.usd
              } ${" "}  ${" USD"}`}</span>
            </Box>
          )}
          {Listoption(
            `CREATOR FEE ${estimateTax?.royaltyFee ?? 0}%`,
            <Box className={"fees-class"}>
              {` ${feeData?.creator_fee?.crypto}  ${
                selectedCurrency?.currency || " WETH"
              }`}{" "}
              <span style={{ paddingLeft: "8px" }}>{`${
                feeData?.creator_fee?.usd
              }  ${" USD"}`}</span>
            </Box>
          )}

          {usdPrice?.usd !== "" && (
            <>
              <Grid item xs={6} sx={{ marginTop: "30px" }}>
                <Typography className={"revenue"}>TOTAL</Typography>
              </Grid>
              <Grid item xs={6} sx={{ marginTop: "30px" }}>
                <Typography className={"revenue revenue-value"} align="right">
                  {` ${feeData?.total_revenue?.crypto}  ${
                    selectedCurrency?.currency || " WETH"
                  }`}{" "}
                  <span>{`${feeData?.total_revenue?.usd}  ${
                    usdPrice?.usdName || " USD"
                  }`}</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className="border-line-grid"></div>
              </Grid>
            </>
          )}
          {!isEdit && (
            <Grid item xs={12} className="agree-container">
              <Checkbox 
                checked={ isChecked }
                onChange={(e: any) => setIsChecked(e.target.checked)}
              />
              <Typography className="terms-and-conditions">
                I agree to the{" "}
                <a
                  target="_blank"
                  href="/terms-of-service"
                  className="agree-link">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href="/conditions-of-business"
                  className="agree-link">
                  Conditions of Business.
                </a>
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1}>
          {/*Button */}
          <Grid item xs={12} sx={{ marginTop: "30px" }}>
            {![type_JSON?.SALE_PENDING].includes(type) ? (
              <>
                <SecondaryButton
                  isLoading={buttonLoader?.name === "saleListing"}
                  onClick={() => {
                    handleClick(
                      usdPrice?.crypto,
                      !isEdit ? "Open_for_sale" : "Edit_for_sale",
                      selectedCurrency
                    );
                  }}
                  disabled={
                    kycErrVal ||
                    contactUs ||
                    +usdPrice?.crypto < 0.04 ||
                    isValidNetwork !== chain?.id ||
                    (!isEdit && !isChecked)
                  }>
                  {!isEdit ? "List Item" : "Save"}
                </SecondaryButton>
              </>
            ) : (
              <ModalButton className={"success"}>
                <Typography className="list-item-flow-msg" display={"inherit"}>
                  {listingFlowMsg}
                </Typography>
                <Typography>
                  {listingFlowMsg ? (
                    <Image
                      unoptimized={true}
                      src={"/images/loading.gif"}
                      alt={"loading"}
                      width={30}
                      height={30}
                    />
                  ) : (
                    ""
                  )}
                </Typography>
              </ModalButton>
            )}
          </Grid>

          <Grid item xs={12}>
            <SecondaryButton
              onClick={() => handleClick("", "cancel")}
              buttontype="secondary">
              {[type_JSON.SALE, type_JSON.SALE_PENDING].includes(type)
                ? "Cancel"
                : "Go Back Without Saving"}
            </SecondaryButton>
          </Grid>

          {isEdit && type_JSON?.EDIT_REMOVE !== type && (
            <Grid item xs={12}>
              <SecondaryButton
                buttontype="error"
                onClick={() => handleClick("", "removeListing")}
                isLoading={buttonLoader?.name === "removeListing"}>
                Confirm Listing Removal
              </SecondaryButton>
            </Grid>
          )}

          {isEdit && type_JSON?.EDIT_REMOVE === type && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={9}>
                  <ModalButton className="removal">
                    Confirm Listing Removal
                  </ModalButton>
                </Grid>
                <Grid item lg={3} xs={12}>
                  <ModalButton>Cancel</ModalButton>
                </Grid>
              </Grid>
            </Grid>
          )}

          {isEdit && (
            <Grid item xs={12} className="my-4">
              <Typography
                align="center"
                className="link"
                onClick={() => {
                  handleClick("", "redirectSothebys");
                }}>
                View on Sotheby’s Metaverse
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
)`
  .border-line-grid {
    background: ${palette.list_item_grey};
    height: 1px;
  }
  .switch-network {
    text-decoration-line: underline;
    cursor: pointer;
  }
  .usd-text {
    color: ${palette.black};
    opacity: 0.5;
    margin-bottom: 16px;
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 22px;
  }
  #filter-select-dropdown {
    position: absolute;
    left: 0;
    right: 0;
    .sort-input-change {
      border-radius: 4px;
      background: ${palette.white};
      z-index: 999999;
    }
    .currency {
      margin-left: 24px;
    }
    .menu-item-value {
      height: 54px;
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${palette.black};
    }
  }
  .input-make-offer {
    border: none;
    background: ${palette.white};
    color: ${palette.black};
    font-weight: 400;
    font-size: 13px;
    width: 65px;
    position: absolute;
    z-index: 9999999999999;
    top: 40px;
    left: 2px;
    padding-left: 12px;
  }
  .select-dropdown {
    position: relative;
    &.with-input {
      .menu-item-value {
        padding: 38px 0px 16px 65px !important;
      }
    }
    .select-lable {
      color: ${palette.black};
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 5px;
      position: absolute;
      z-index: 999999999;
      top: 20px;
      left: 13px;
    }
    .MuiGrid-grid-xs-2 {
      padding-top: 20px !important;
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-right: 16px;
    }
    .single-wethaddress {
      pointer-events: none;
      .MuiGrid-grid-xs-2 {
        opacity: 0;
      }
    }
    .sort-input-change .sort-icon-adjustment {
      padding-right: 12px !important;
      display: flex;
      justify-content: flex-end;
    }
    .menu-item-value {
      height: 74px !important;
      padding: 38px 0px 16px 12px !important;
    }
    .menu-item {
      padding: 8px 12px !important;
      &.open {
        padding: 16px 0px 8px 12px !important;
      }
    }
    .select-dropdown .menu-item-value {
      padding: 40px 0px 16px 12px !important;
    }
    #filter-select-dropdown {
      .sort-input-change {
        z-index: 99999999;
      }
    }
  }
  .error-card {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: rgb(255, 218, 213);
    margin-bottom: 10px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid rgb(189, 34, 0);
    p {
      font-size: 12px;
      color: rgb(189, 34, 0);
      b {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .crypto-val {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${palette.black};
    p {
      font-family: "BentonSans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: ${palette.black};
    }
  }
  .input-tag {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: ${palette.black};
  }
  .errorMsg {
    font-family: "BentonSans";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.1px;
    color: red;
    text-align: left;
    position: relative;
  }
  .boxstyle {
    color: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 70px;
    border-radius: 3px;
    padding: 16px 16px 16px 17px;
    .usd-cal {
      float: right;
      font-family: BentonSans;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 22px;
      letter-spacing: -0.1px;
      color: #ffffff;
      opacity: 0.5;
    }
  }
  .border-line {
    margin: 20px 0px 16px 0px;
    height: 0px;
    border: 1px solid ${palette.black};
    opacity: 0.2;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .colorWhite-val{
    font-size: 15px;
    font-family: "BentonSans";
    font-weight: 400;
    span {
      font-size: 10px;
      font-weight: 700;
    }
  }
  .colorWhite {
    font-size: 12px;
    font-family: "BentonSans";
    font-weight: 700;
    span {
      margin-left: 8px;
      font-size: 10px;
      opacity: 0.5;
    }
  }
  .border-line-grid {
    background: ${palette.list_item_grey};
    height: 1px;
  }
  .inputtag {
    font-size: 10px;
    font-family: "BentonSans";
  }
  .button-padding {
    padding-top: 50px;
  }
  .revenue {
    color: ${palette.primaryGreen};
    font-size: 12px;
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
  .revenue span {
    opacity: 0.5;
    font-size: 10px;
  }
  .success {
    border: 1px solid #2be0ed;
    background: #1c7670;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .list-item-flow-msg {
    color: ${palette.white}
  }
  .success p {
    font-size: 12px;
  }
  .removal {
    background: #90030f;
  }
  .link {
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
  }
  .token-type-box {
    width: 66px;
    height: 24px;
    background: #424242;
    border-radius: 25px;
    display: inline-grid;
    margin-left: 10px;
    paddingL 2px;
    justify-content: center;
    align-items: center;
  }
  .token-type {
    font-family: BentonSans;
    font-weight: 600;
    font-size: 12px;
    color: ${palette.white};
    margin-top: 3px;
  }
  input:focus {
    outline: none;
  }
  .box-input {
    border: none;
    background: #222222;
    color: #ffff;
    font-weight: 400;
    font-size: 13px;
    width: 60px;
  }
  .fees-class {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .fees-class {
      display: inline-grid;
    }
    .revenue-value {
      display: inline-grid;
      float: right;
      font-weight: 400 !important;
    }
    .input-make-offer {
      top: 50px;
    }
    .usd-text {
      margin-top: 40%;
    }
  }
  .terms-and-conditions {
    font-weight: 400;
    font-size: 12px;
    font-family: "BentonSans";
    margin-left: 5px;
  }
  .agree-container {
    display: flex;
    align-items: center;
  }
  .agree-link {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: ${palette?.navyBlue};
    }
  }
`;
export const NftSaleModal = styled(
  ({
    modelopen,
    isEdit,
    type,
    organization,
    handleClick,
    priceVal,
    toastErrorModalOpen,
    toastErrorModalClose,
    listingFlowMsg,
    buttonLoader,
    nftData,
    isValidNetwork,
    twitterUrl,
    supportedCurrencies,
    estimateTax,
    ...props
  }: NftSaleModel) => {
    //const [mediaControl, setMediaControl] = useState<boolean>(false);
    const [dynamicSize, setDynamicSize] = useState({
      height: "600px",
      width: "550px",
    });

    const handleSwitchNetwork = async () => {
      try {
        const hexValue = parseInt(String(isValidNetwork), 10).toString(16);
        const chain = hexValue.padStart(6, "");
        await OnSwitchNetwork(chain);
      } catch (error) {
        console.log(error);
      }
    };

    //SALE SALE_PENDING SALE_SUCCESS
    const type_JSON = {
      SALE: "SALE",
      SALE_PENDING: "SALE_PENDING",
      SALE_SUCCESS: "SALE_SUCCESS",
      SALE_FAILED: "SALE_FAILED",
      EDIT: "EDIT",
      EDIT_REMOVE: "EDIT_REMOVE",
      EDIT_SUCCESS: "EDIT_SUCCESS",
      EDIT_FAILED: "EDIT_FAILED",
    };

    const BUTTON_COMPONENT = {
      SALE_SUCCCESS: (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginTop: "30px" }}>
            <ModalButton
              onClick={() => handleClick("", "success")}
              className="success">
              <Typography display={"inherit"}>Success!</Typography>
              <Typography>
                {!isEdit
                  ? "Item has been listed."
                  : "Listing updates have been saved."}
              </Typography>
            </ModalButton>
          </Grid>

          <Grid item xs={12}>
            <ModalButton
              className="success-button"
              onClick={() => handleClick("", "redirectSothebys")}>
              View on Sotheby’s Metaverse
            </ModalButton>
          </Grid>

          <Grid item xs={12}>
            <TwitterShareButton
              style={{ width: "100%" }}
              title="You have been invited to view the NFT from Mojito Marketplace. Click on the following link for more details:"
              url={twitterUrl}>
              <ModalButton className="success-button">
                <SVG src="/icons/twitter_black.svg" className="twitter" />
                Share on Twitter
              </ModalButton>
            </TwitterShareButton>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" className="site">
              You will be redirected to a 3rd party site
            </Typography>
          </Grid>
        </Grid>
      ),
      SALE_FAILED: (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{}}>
            <ModalButton
              onClick={() => handleClick("", "failed")}
              className="removal">
              {[type_JSON.SALE_FAILED, type_JSON.EDIT_FAILED].includes(type) ? (
                <>
                  <Typography className="error-text" display={"inherit"}>
                    Item Listing Failed!
                  </Typography>
                  <Typography className="error-text">
                    A problem was encountered while listing your item.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="error-text" display={"inherit"}>
                    Saving changes failed.
                  </Typography>
                  <Typography className="error-text">
                    A problem was encountered while saving your item listing
                    updates.
                  </Typography>
                </>
              )}
            </ModalButton>
          </Grid>

          <Grid item xs={12}>
            <SecondaryButton
              onClick={() => handleClick("", "retry")}
              buttontype="secondary">
              Retry
            </SecondaryButton>
          </Grid>

          <Grid item xs={12}>
            <SecondaryButton
              onClick={() => handleClick("", "cancel")}
              buttontype="secondary">
              Cancel
            </SecondaryButton>
          </Grid>

          {type_JSON.EDIT_FAILED === type && (
            <Grid item xs={12}>
              <ModalButton onClick={() => handleClick("", "redirectSothebys")}>
                View on Sotheby’s Metaverse
              </ModalButton>
            </Grid>
          )}
        </Grid>
      ),
    };

    const renderBTNComponent = () => {
      if ([type_JSON?.SALE_SUCCESS, type_JSON?.EDIT_SUCCESS].includes(type))
        return BUTTON_COMPONENT.SALE_SUCCCESS;
      else if ([type_JSON?.SALE_FAILED, type_JSON?.EDIT_FAILED].includes(type))
        return BUTTON_COMPONENT.SALE_FAILED;
    };

    const ButtonComponent = styled((props) => {
      return (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
          lg={6}
          {...props}>
          <Box className="btn-align">{renderBTNComponent()}</Box>
        </Grid>
      );
    })`
      .success {
        border: 1px solid #15812c;
        background: #eafee9;
        height: auto;
        padding: 10px 10px;
      }
      .success p {
        font-size: 12px;
      }
      .removal {
        border: 1px solid #bf1e18;
        background: #bf1e18;
        height: auto;
        padding: 10px 10px;
        border-radius: 8px;
      }
      .error-text {
        color: ${palette.white};
        font-family: BentonSans;
        font-weight: 400;
        font-size: 12px;
      }
      .removal p {
        font-size: 12px;
      }
      .twitter {
        padding-right: 5px;
        width: 20px;
        height: 16px;
      }
      .btn-align {
        width: 100%;
      }
      .site {
        font-family: BentonSans;
        font-style: italic;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: ${palette.gray};
      }
      @media only screen and (max-width: 768px) {
        .btn-align {
          width: 100%;
          margin-top: 0%;
        }
      }
    `;

    return (
      <>
        <Modal
          {...props}
          open={modelopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: 99999999, backdropFilter: "blur(3px)" }}>
          <Grid>
            <ToastComponent
              type={"error"}
              message={toastErrorModalOpen?.msg || ""}
              open={toastErrorModalOpen?.open || false}
              handleClose={toastErrorModalClose}
            />
            <Box sx={itemsstyle}>
              <Grid container className="title-grid">
                <Grid item xs={10}>
                  <Typography className={"header"}>
                    {!isEdit ? "LIST ITEM FOR SALE" : "EDIT LISTING"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    align="right"
                    onClick={() => {
                      handleClick("", "cancel");
                    }}>
                    <CloseIcon className="close-icon" />
                  </Typography>
                </Grid>
              </Grid>

              {/*image */}
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6} className={"pos-img"}>
                  <MediaImageComponent
                    mediaImage={nftData?.mediaImage ?? ""}
                    mediaExtension={nftData?.mediaExtension ?? ""}
                    image={nftData?.imgUrl}
                    animationURL={nftData?.animatedUrl}
                    secondImage={""}
                    dynamicSize={dynamicSize}
                    layout="responsive"
                    objectFit="contain"
                    handleImagesize={(height, width) => {
                      setDynamicSize({ height: height, width: width });
                    }}
                  />
                  {[
                    type_JSON?.SALE,
                    type_JSON?.EDIT,
                    type_JSON?.EDIT_REMOVE,
                    type_JSON?.SALE_PENDING,
                  ].includes(type) && (
                    <Box sx={{ paddingTop: "30px" }}>
                      <Typography className={"nft-deatils-title"}>
                        {nftData?.artistName}
                      </Typography>
                      <Typography className={"nft-deatils-description"}>
                        {!isEmpty(nftData?.name)
                          ? nftData?.name
                          : `# ${
                              String(nftData?.tokenId ?? "")?.length > 10
                                ? truncateAddress(String(nftData?.tokenId))
                                : nftData?.tokenId
                            }`}
                      </Typography>
                      <Typography
                        sx={{ marginBottom: 1.5 }}
                        className={"nft-deatils-description"}>
                        {nftData?.year}
                      </Typography>
                      <div
                        style={{ marginBottom: "12px" }}
                        className="border-line-grid"></div>
                      <Box className="contract-details">
                        <Typography className={"header"}>
                          Contract address:
                        </Typography>
                        <Typography
                          onClick={() => {
                            const url = CheckNetworkTokens(
                              isValidNetwork,
                              nftData?.contractAddress,
                              nftData?.tokenId
                            );
                            window.open(url, "_blank");
                          }}
                          className={"nft-deatils-description text-underline"}>
                          {nftData?.contractAddress?.substring(0, 6) +
                            "..." +
                            nftData?.contractAddress?.substring(
                              nftData?.contractAddress?.length - 4
                            )}
                        </Typography>
                      </Box>
                      <Box className="contract-details">
                        <Typography className={"header"}>Token ID:</Typography>
                        <Typography
                          onClick={() => {
                            if (nftData?.tokenId)
                              window.open(nftData?.tokenURI, "_ blank");
                          }}
                          className={"nft-deatils-description text-underline"}>
                          {String(nftData?.tokenId ?? "")?.length > 10
                            ? truncateAddress(String(nftData?.tokenId))
                            : nftData?.tokenId}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Grid>
                {[
                  type_JSON.EDIT_FAILED,
                  type_JSON.EDIT_SUCCESS,
                  type_JSON.SALE_FAILED,
                  type_JSON.SALE_SUCCESS,
                ].includes(type) ? (
                  <ButtonComponent />
                ) : (
                  <ListItem
                    type_JSON={type_JSON}
                    type={type}
                    handleClick={handleClick}
                    isEdit={isEdit}
                    priceVal={priceVal}
                    buttonLoader={buttonLoader}
                    nftData={nftData}
                    listingFlowMsg={listingFlowMsg}
                    isValidNetwork={isValidNetwork}
                    organization={organization}
                    supportedCurrencies={supportedCurrencies}
                    estimateTax={estimateTax}
                    handleSwitchNetwork={handleSwitchNetwork}
                  />
                )}
              </Grid>
            </Box>
          </Grid>
        </Modal>
      </>
    );
  }
)`
  .border-line-grid {
    background: ${palette.list_item_grey};
    height: 1px;
  }
  .nft-deatils-description {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  .nft-deatils-title {
    font-family: Mercury Display;
    font-weight: 325;
    font-size: 25px;
    line-height: 30px;
  }
  .text-underline {
    text-decoration-line: underline;
    cursor: pointer;
  }
  .contract-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .nft-image {
    position: relative;
  }
  .pos-img {
    position: relative;
    .play-button {
      position: absolute;
      right: 10px;
      cursor: pointer;
      bottom: 10px;
    }
  }
  .success-button {
    background: ${palette.white};
    border: 1px solid rgba(4, 36, 57, 0.3);
    color: ${palette.navyBlue};
    font-weight: 700;
  }

  p {
    color: ${palette.black};
    font-family: "BentonSans";
  }
  .header {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }
  .title-grid {
    margin-bottom: 20px;
    .header {
      padding: 10px 0px;
    }
    .close-icon {
      cursor: pointer;
      color: #bdbdbd;
      width: 15px;
    }
  }
  @media only screen and (max-width: ${EViewSize.mobile}) {
    .title-grid {
      margin-bottom: 16px;
    }
  }
`;

export const IvalidChainModel = styled(
  ({ modelopen, ErrorMsg, ...props }: IModel) => {
    return (
      <div>
        <Modal
          {...props}
          open={modelopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: 99999999 }}>
          <Box sx={style}>
            <Typography align="center" className="heading">
              <Loader contained={true} width={200} />
            </Typography>
            {ErrorMsg && (
              <p
                className="content"
                dangerouslySetInnerHTML={{ __html: ErrorMsg }}></p>
            )}
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .heading {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .content {
    font-size: 13px;
    padding-bottom: 20px;
    font-family: BentonSans;
    display: block;
    text-align: center;
  }
`;

interface IModelMetamask {
  modelopen: boolean;
  onHandleClose?: () => void;
  title?: string;
  content?: string;
  cancel?: boolean;
  hash?: string;
  chainExplorer?: string;
}
const MetaMaskConnect = {
  position: "absolute",
  left: "50%",
  top: "6%",
  transform: "translate(-50%, 17%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, -7%)",
    width: "100%",
    height: "100%",
  },
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};
export const MetaMaskNewAccount = styled(
  ({
    modelopen,
    title = "Connecting Wallet",
    content = "Follow browser prompts to connect your wallet.<p>Please do not close or reload the page…</p>",
    hash = "",
    cancel = true,
    chainExplorer = "",
    onHandleClose,
    ...props
  }: IModelMetamask) => {
    return (
      <div>
        <Modal
          {...props}
          open={modelopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: 99999999 }}>
          <Box sx={MetaMaskConnect}>
            <Typography align="center" className="heading">
              {title}
            </Typography>
            <Typography
              align="center"
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}></Typography>
            <Typography align="center" className="image-loading">
              <Loader contained={true} width={200} />
            </Typography>
            {!isEmpty(hash) && content.includes("Transaction in progress") && (
              <Typography
                sx={{
                  fontFamily: "BentonSans",
                  fontWeight: 500,
                  fontSize: "12px",
                  textAlign: "center",
                  color: "#000000",
                  paddingTop: "16px",
                  justifyContent: "center",
                }}>
                {"Transaction ID"}
                <br />
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: 400,
                    color: "#042439",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(`${chainExplorer}/tx/${hash}`);
                  }}>
                  {truncateAddress(hash)}
                </span>
              </Typography>
            )}
            {cancel && (
              <Typography
                align="center"
                className="cancel"
                onClick={() => onHandleClose?.()}>
                Cancel
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .heading {
    font-family: Mercury Text G1;
    font-weight: 325;
    font-size: 24px;
    margin-top: 10px;
  }
  .image-loading {
    margin-top: 16px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .cancel {
    font-family: BentonSans;
    font-weight: 500;
    font-size: 12px;
    margin-top: 36px;
    text-decoration: underline;
    cursor: pointer;
  }
  .content {
    font-family: BentonSans;
    font-weight: 400;
    font-size: 15px;
    margin-top: 16px;
  }
`;

const MakeOfferSubmitstyle = {
  position: "absolute",
  left: "50%",
  top: "6%",
  transform: "translate(-50%, 45%)",
  "@media screen and (max-width: 768px)": {
    transform: "translate(-50%, -6%)",
    width: "100%",
    height: "100%",
  },
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const MakeOfferSubmit = styled(
  ({ modelopen, content = "", onHandleClose, ...props }: IModelMetamask) => {
    return (
      <div>
        <Modal
          {...props}
          open={modelopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ zIndex: 99999999 }}>
          <Box
            sx={[
              MakeOfferSubmitstyle,
              {
                maxWidth: {
                  lg: "580px",
                  xl: "580px",
                  md: "580px",
                  sm: "100%",
                  xs: "100%",
                },
                padding: "64px",
                "@media screen and (max-width: 768px)": {
                  padding: "0px 18px 0px 18px",
                },
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              },
            ]}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  margin: "32px 0px 32px 0px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}>
                <CheckCircleOutlineIcon
                  sx={{ color: "#31A136", fontSize: 120 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" className="content">
                  Offer Accepted!
                </Typography>
                <Typography align="center" className="content">
                  {content}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ marginTop: "40px" }}>
                <SecondaryButton
                  buttontype="primary"
                  onClick={() => {
                    onHandleClose?.();
                  }}>
                  Back to Marketplace
                </SecondaryButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </div>
    );
  }
)`
  .heading {
    font-family: Mercury Text G1;
    font-weight: 325;
    font-size: 24px;
    margin-top: 10px;
  }
  .cancel {
    font-family: BentonSans;
    font-weight: 500;
    font-size: 12px;
    margin-top: 36px;
    text-decoration: underline;
    cursor: pointer;
  }
  .content {
    font-family: BentonSans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;
