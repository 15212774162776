import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {
  copyToClipboard,
  isEmpty,
  PDPURL,
  truncateAddress,
  truncateDecimal,
  formatNumberToUSD,
} from "@utils/wallet/numberFormet.util";
import ToastComponent from "@secondary/toast";
import { getChainExplorer } from "@utils";
import { useRouter } from "next/router";
import Image from "next/image";
import { useOrganization } from "@hooks";
import { config } from "@constants";
import isBrowser from "@utils/isBrowser";
import { SecondaryButton } from "../secondaryButton";
import { palette } from "@styles/theme";
import styled from "styled-components";
import { IAddress, selectedCurrencyProps } from "./buyNowMakeOfferModal";
import { MediaImageComponent } from "@layouts/profile/wallet/components/MediaImageComponet";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 1,
  transform: "translate(-50%, -50%)",
  backgroundColor: palette.white,
  boxShadow: 24,
  borderRadius: 2,
  maxHeight: "90%",
  maxWidth: "580px",
  "@media screen and (max-width: 768px)": {
    width: "95% !important",
    overflow: "auto",
  },
};

const loaderStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: 1,
  transform: "translate(-50%, -50%)",
  backgroundColor: palette.white,
  boxShadow: 24,
  borderRadius: 2,
  maxHeight: "90%",
  maxWidth: "580px",
  "@media screen and (max-width: 768px)": {
    width: "30% !important",
    overflow: "auto",
  },
};

interface CheckoutModel {
  buttonDisable: boolean;
  buttonLoader: string;
  modalOpen: boolean;
  handleClose: () => void;
  types: string;
  warnings?: string;
  handleBuyNowOrMakeOffer: (name: string) => void;
  quantity: number;
  loading: boolean;
  price: {
    value: number;
    unit: string;
    type: string;
  }[];
  transactionHash: string;
  processingTitle: string;
  processingContent: string;
  isBuy: boolean;
  isSwap: boolean;
  handleSwapAmount: (
    val: number,
    taxPercent: number,
    usdAmount: number,
    address: IAddress,
    selectedCurrencydata: selectedCurrencyProps
  ) => void;
  handleSwapCancel: () => void;
  handleSwitchNetwork: () => void;
  handleRefetchBalance: () => void;
  orderId: string;
  latestPrice: {
    value: number;
    unit: string;
    type: string;
  }[];
  isRecheck: boolean;
  toastMsg: { toastOpen: boolean; msg: string };
  handleCloseToast?: () => void;
  onRefetchData: () => void;
  taxPercentage: number;
  makeOfferSwapAmount: string;
  selectedCurrencydata: selectedCurrencyProps;
  NFTDetailsState: {
    contractAddress: string;
    tokenId: string;
    tokenType: string;
    ownerAddress: string;
    nftTokenId: string;
    network: string;
    image: string;
    animationURL?: string;
    name: string;
    mediaImage: string;
    mediaExtension: string;
  };
  connect: {
    buyerAddress: string;
    currency: string;
    ethOrMaticBalance: number;
    wethOrWmaticBalance: number;
    chainId: number;
  };
  address: IAddress;
  cryptoBID: number;
  updateNftTokenId: string;
}

const Checkout = styled((props: CheckoutModel): JSX.Element => {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);
  const [isRefetchBal, setIsRefetchBal] = useState({
    nativeCurrency: false,
    privateCurrency: false,
  });
  const chainExplorer = getChainExplorer(props.connect?.chainId ?? 0);
  const address =
    props.connect?.buyerAddress?.substring(0, 8) +
    "..." +
    props.connect?.buyerAddress?.substring(
      props.connect?.buyerAddress?.length - 3
    );
  const usdTax = props?.taxPercentage * props?.cryptoBID;

  const copycontent = (arg: string | null) => {
    copyToClipboard(String(arg)).then(() => {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    });
  };

  const handleCopyAddress = (e: string) => {
    if (e === "wallet") {
      if (props.connect?.buyerAddress) {
        copycontent(props.connect?.buyerAddress);
      }
    } else if (e === "weth") {
      if (props.selectedCurrencydata?.marketPlaceContractAddress) {
        copycontent(props.selectedCurrencydata?.marketPlaceContractAddress);
      }
    } else {
      if (props.orderId) {
        copycontent(props.orderId);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewItem = () => {
    if ([router.asPath].includes("/marketplace/asset/details/")) {
      props.handleClose();
    } else {
      router.push(
        PDPURL(
          props?.NFTDetailsState?.contractAddress,
          props?.NFTDetailsState?.tokenId,
          props?.NFTDetailsState?.tokenType,
          props?.NFTDetailsState?.ownerAddress ?? ""
        )
      );
    }
  };

  const handleConfirmClose = () => {
    props.onRefetchData();
    props.handleClose();
  };

  const handleBackToMarketPlace = () => {
    router.push("/marketplace/viewall");
    props.handleClose();
  };

  const refetchNativeBalance = () => {
    setIsRefetchBal({
      nativeCurrency: true,
      privateCurrency: false,
    });
    props.handleRefetchBalance();
    setTimeout(() => {
      setIsRefetchBal({
        nativeCurrency: false,
        privateCurrency: false,
      });
    }, 1000);
  };

  const refetchBalance = () => {
    setIsRefetchBal({
      nativeCurrency: false,
      privateCurrency: true,
    });
    props.handleRefetchBalance();
    setTimeout(() => {
      setIsRefetchBal({
        nativeCurrency: false,
        privateCurrency: false,
      });
    }, 1000);
  };

  const { organization } = useOrganization();
  const cryptoPriceValue =
    props?.types === "summary"
      ? props?.price[1]?.value
      : props.latestPrice?.[1]?.value;
  const totalTaxUsd = props.price[0]?.value + usdTax;
  const totalTaxEthOrMatic = cryptoPriceValue + props?.taxPercentage;
  const swapAmount = +truncateDecimal(
    totalTaxEthOrMatic - Number(props?.connect?.wethOrWmaticBalance),
    4
  );
  return (
    <Modal
      open={props.modalOpen}
      sx={{ backgroundColor: "gba(0, 0, 0, 0.5)", backdropFilter: "blur(4px)" }}
      // onClose={props.handleClose}
      // aria-labelledby="parent-modal-title"
      // aria-describedby="parent-modal-description"
    >
      <Box {...props}>
        {props.loading ? (
          <Box sx={loaderStyle}>
            <Box
              style={{
                overflow: "hidden auto",
                maxHeight: "calc(100vh - 100px)",
              }}>
              <Image
                unoptimized={true}
                src={"/images/loader_sothebys.gif"}
                height={100}
                width={100}
                alt="loading"
              />
            </Box>
          </Box>
        ) : props.types === "summary" ? (
          <Box sx={style}>
            <Box
              style={{
                overflow: "hidden auto",
                maxHeight: "calc(100vh - 100px)",
              }}>
              {props.toastMsg.toastOpen && (
                <ToastComponent
                  type={"error"}
                  message={props.toastMsg.msg || ""}
                  open={props.toastMsg.toastOpen || false}
                  handleClose={props.handleCloseToast}
                />
              )}
              <Grid container spacing={1} style={{}}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={props.handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} className="centerStyle">
                  <Typography
                    style={{
                      fontSize: 24,
                      fontFamily: "Mercury Text G1",
                      fontWeight: 500,
                    }}>
                    {props.isBuy ? "Checkout" : "Make Offer"}
                  </Typography>
                </Grid>
              </Grid>
              <Box
                sx={{
                  paddingLeft: { sm: 2, xs: 2, md: 5, lg: 5, xl: 5 },
                  paddingRight: { sm: 2, xs: 2, md: 5, lg: 5, xl: 5 },
                  paddingTop: { sm: 1, xs: 1, md: 3, lg: 3, xl: 3 },
                  paddingBottom: { sm: 1, xs: 1, md: 3, lg: 3, xl: 3 },
                }}>
                <Box className="checkout-container">
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className="walletAddress"
                      sx={{ marginBottom: 2 }}>
                      <Box
                        sx={{
                          display: {
                            sm: "block",
                            xs: "block",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                        }}>
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "BentonSans",
                            fontWeight: 500,
                          }}>
                          Wallet address:
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 12,
                            fontFamily: "BentonSans",
                            fontWeight: 500,
                            marginLeft: "2px",
                          }}>
                          {address}
                        </Typography>
                        <IconButton
                          sx={{
                            height: 8,
                            width: 8,
                            marginLeft: 1.5,
                            display: {
                              sm: "none",
                              xs: "none",
                              md: "flex",
                              lg: "flex",
                              xl: "flex",
                            },
                          }}
                          onClick={() => handleCopyAddress("wallet")}
                          aria-label="close">
                          <FileCopyIcon
                            style={{ fontSize: 16, color: "#9E9E9E" }}
                          />
                        </IconButton>
                        <ToastComponent
                          type={"success"}
                          message={"copied to clipboard"}
                          open={open}
                          handleClose={handleClose}
                        />
                      </Box>
                      <Box>
                        <Button className="connectButton" variant="outlined">
                          Connected
                        </Button>
                      </Box>
                    </Grid>
                    <Box className="divider" sx={{ marginBottom: 1 }} />
                    <Grid item xs={12} className="walletAddress">
                      {props.connect?.wethOrWmaticBalance ? (
                        <>
                          <Typography className="currencyText">
                            {props.connect?.currency === "WETH"
                              ? "WETH Balance:"
                              : "WMATIC Balance:"}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <Typography
                              style={{ marginRight: "5px" }}
                              className="currencyText">
                              {truncateDecimal(
                                +props.connect?.wethOrWmaticBalance,
                                4
                              )}{" "}
                              {props.connect?.currency === "WETH"
                                ? "WETH"
                                : "WMATIC"}
                            </Typography>
                            {isRefetchBal?.privateCurrency ? (
                              <Image
                                src={"/images/loading.gif"}
                                alt={"refresh"}
                                width={15}
                                height={12}
                              />
                            ) : (
                              <Image
                                src={"/images/refresh.png"}
                                alt={"refresh"}
                                width={15}
                                height={12}
                                onClick={() => refetchBalance()}
                              />
                            )}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography className="currencyText">
                            {props.connect?.currency === "WETH"
                              ? "WETH Balance:"
                              : "WMATIC Balance:"}
                          </Typography>
                          <Typography className="currencyText">
                            {"0 "}
                            {props.connect?.currency === "WETH"
                              ? "WETH"
                              : "WMATIC"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} className="walletAddress">
                      {props?.connect?.ethOrMaticBalance ? (
                        <>
                          <Typography className="balance">
                            {props.connect?.currency === "WETH"
                              ? "ETH Balance:"
                              : "MATIC Balance:"}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <Typography
                              style={{ marginRight: "20px" }}
                              className="balance">
                              {truncateDecimal(
                                +props?.connect?.ethOrMaticBalance,
                                4
                              )}{" "}
                              {props.connect?.currency === "WETH"
                                ? "ETH"
                                : "MATIC"}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography className="balance">
                            {props.connect?.currency === "WETH"
                              ? "ETH Balance:"
                              : "MATIC Balance:"}
                          </Typography>
                          <Typography className="balance">
                            {"0 "}
                            {props.connect?.currency === "WETH"
                              ? "ETH"
                              : "MATIC"}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  {props.warnings === "checkBalance" ? (
                    <Grid item xs={12} className="warningContainer">
                      {props.isSwap ? (
                        <Typography className="warningText">
                          Your connected wallet currently does not have enough{" "}
                          <b>
                            {props.connect?.currency === "WETH"
                              ? "WETH"
                              : "WMATIC"}
                            .
                          </b>{" "}
                          Would you like to swap <b>{swapAmount}</b> from{" "}
                          <b>
                            {props.connect?.currency === "WETH"
                              ? "ETH"
                              : "MATIC"}
                          </b>{" "}
                          to{" "}
                          <b>
                            {props.connect?.currency === "WETH"
                              ? "WETH"
                              : "WMATIC"}
                          </b>
                          ?{" "}
                          <Typography
                            display="inline"
                            onClick={() =>
                              props.handleSwapAmount(
                                props?.price[1]?.value,
                                props.taxPercentage,
                                props.price[0]?.value,
                                props.address,
                                props.selectedCurrencydata
                              )
                            }
                            className="underlineStyle">
                            <b>Click here to proceed.</b>
                          </Typography>
                        </Typography>
                      ) : (
                        <Typography className="warningText">
                          Your connected wallet currently does not have enough{" "}
                          <b>
                            {props.isBuy
                              ? props.selectedCurrencydata?.unit
                              : props.price[1]?.unit}
                          </b>{" "}
                          to complete your purchase.{" "}
                          <a
                            href={`https://sothebys-metaverse-dev.mojito.xyz/lfc/faq`}
                            target="_blank"
                            rel="noreferrer">
                            <Typography
                              display="inline"
                              className="underlineStyle">
                              <b>
                                How do I get{" "}
                                {props.isBuy
                                  ? props.selectedCurrencydata?.unit
                                  : props.price[1]?.unit}
                                ?
                              </b>
                            </Typography>
                          </a>
                        </Typography>
                      )}
                    </Grid>
                  ) : props.warnings === "wrongNetwork" ? (
                    <Grid item xs={12} className="warningContainer">
                      <Typography className="warningText">
                        Please{" "}
                        <Typography
                          display="inline"
                          onClick={props.handleSwitchNetwork}
                          className="underlineStyle">
                          <b>
                            Switch to {props.NFTDetailsState?.network} Network
                          </b>
                        </Typography>{" "}
                        in order to complete checkout.-
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
                {organization?.kycStatus !== "Clear" && totalTaxUsd > 10000 && (
                  <Grid item xs={12} className="warningContainer">
                    <Typography className="warningText">
                      Please{" "}
                      <b
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "underline",
                        }}
                        onClick={() => {
                          window.open(
                            config.SOTHEBYS_DOMAIN +
                              "/buy/register/personal-information?origin=sothebys-metaverse&redirect=" +
                              (isBrowser &&
                                encodeURIComponent(window.location.href)),
                            "_blank"
                          );
                        }}>
                        complete your profile
                      </b>{" "}
                      to list item more than 10K USD.
                    </Typography>
                  </Grid>
                )}
                <Box>
                  <Grid container spacing={0}>
                    <Grid item xs={12} className="billing">
                      <Box>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 600 }}>
                          Billing
                        </Typography>
                      </Box>
                      <Box className="address">
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          {props?.address?.street}
                        </Typography>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          {props?.address?.city}, {props?.address?.state}
                        </Typography>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          {props?.address?.country?.lable},{" "}
                          {props?.address?.postalCode}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  className="divider"
                  sx={{ marginBottom: 2, marginTop: 2 }}
                />
                <Box>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={2.5}
                      lg={2.5}
                      xl={2.5}
                      sx={{ height: "100px" }}>
                      <MediaImageComponent
                        mediaImage={props.NFTDetailsState?.mediaImage}
                        mediaExtension={props.NFTDetailsState?.mediaExtension}
                        image={props.NFTDetailsState?.image}
                        animationURL={props.NFTDetailsState?.animationURL ?? ""}
                        secondImage={""}
                        dynamicSize={{ height: "80px", width: "80px" }}
                        autoPlay={false}
                        videoSize={{ height: "80px", width: "80px" }}
                        layout="responsive"
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} md={5} lg={5} xl={5}>
                      <Box style={{ height: 80, padding: 5 }}>
                        <Typography
                          className="textStyle text-overlap-style"
                          sx={{ marginBottom: 4, fontWeight: 600 }}>
                          {props.NFTDetailsState?.name?.length > 0
                            ? props?.NFTDetailsState?.name
                            : "-"}
                        </Typography>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          {props.quantity}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4.5}
                      lg={4.5}
                      xl={4.5}
                      sx={{
                        marginLeft: { sm: 0, xs: 0, md: 3, lg: 0, xl: 0 },
                        marginTop: { sm: 2, xs: 2, md: 0, lg: 0, xl: 0 },
                        marginBottom: { sm: 2, xs: 2, md: 0, lg: 0, xl: 0 },
                      }}>
                      <Box
                        sx={{
                          height: { sm: 0, xs: 0, md: 80, lg: 80, xl: 80 },
                          padding: { sm: 0, xs: 0, md: 0, lg: 0, xl: 0 },
                          display: "flex",
                          justifyContent: {
                            lg: "flex-end",
                            xl: "flex-end",
                            md: "flex-end",
                            sm: "flex-start",
                            xs: "flex:start",
                          },
                          alignItems: {
                            lg: "flex-end",
                            xl: "flex-end",
                            md: "flex-end",
                            sm: "flex-start",
                            xs: "flex:start",
                          },
                        }}>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          {`${truncateDecimal(props?.price?.[1]?.value, 4)} ${
                            props.selectedCurrencydata?.unit
                          }`}{" "}
                          {` (${formatNumberToUSD(
                            props?.price[0]?.value,
                            4
                          )} USD)`}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="divider"
                  sx={{ marginBottom: 2, marginTop: 2 }}
                />
                <Grid container style={{ marginBottom: 15 }}>
                  <Grid xs={3}>
                    <Typography className="taxStyle">Taxes</Typography>
                  </Grid>
                  <Grid item xs={9} className="endStyle">
                    <Typography className="textStyle">
                      {`${props?.taxPercentage}${" "}${
                        props.selectedCurrencydata?.unit
                      }`}{" "}
                      {` (${truncateDecimal(usdTax, 4)} USD)`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography className="currencyText">Total</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    sx={{
                      justifyContent: {
                        lg: "flex-end",
                        xl: "flex-end",
                        md: "flex-end",
                        sm: "flex-start",
                        xs: "flex:start",
                      },
                      alignItems: {
                        lg: "flex-end",
                        xl: "flex-end",
                        md: "flex-end",
                        sm: "flex-start",
                        xs: "flex:start",
                      },
                      display: "flex",
                    }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: palette.success,
                        fontFamily: "BentonSans",
                        fontWeight: 500,
                        marginTop: { sm: 2, xs: 2, md: 0, lg: 0, xl: 0 },
                      }}>
                      {`${truncateDecimal(totalTaxEthOrMatic, 4)} ${
                        props.selectedCurrencydata?.unit
                      }`}{" "}
                      {` (${
                        truncateDecimal(totalTaxUsd, 4) +
                        " " +
                        props.price?.[0]?.unit
                      })`}
                    </Typography>
                  </Grid>
                  {props.warnings === "checkBalance" ? (
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className="centerStyle"
                        sx={{ marginBottom: 2 }}>
                        <SecondaryButton
                          isLoading={props.isRecheck}
                          buttontype="secondary"
                          onClick={props.handleRefetchBalance}>
                          Re-Check Balance
                        </SecondaryButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                    </Grid>
                  ) : props.warnings === "wrongNetwork" ? (
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className="centerStyle"
                        sx={{ marginBottom: 2 }}>
                        <SecondaryButton
                          buttontype="primary"
                          onClick={props.handleSwitchNetwork}>
                          Switch Network
                        </SecondaryButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className="centerStyle"
                        sx={{ marginBottom: 2 }}>
                        <SecondaryButton
                          disabled={true}
                          buttontype="secondary"
                          onClick={props.handleSwitchNetwork}>
                          Checkout
                        </SecondaryButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                    </Grid>
                  ) : (
                    <Grid container style={{ marginTop: 20 }}>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={10}
                        lg={10}
                        xl={10}
                        className="centerStyle"
                        sx={{ marginBottom: 2 }}>
                        <SecondaryButton
                          className="submit-button"
                          disabled={
                            organization?.kycStatus !== "Clear" &&
                            totalTaxUsd > 10000
                              ? true
                              : props.buttonDisable
                          }
                          buttontype="primary"
                          isLoading={
                            props.buttonLoader === "submit_offer" ? true : false
                          }
                          onClick={() => {
                            setOpen(false);
                            props.handleBuyNowOrMakeOffer(
                              props.isBuy ? "buy_now" : "submit_offer"
                            );
                          }}>
                          {props.isBuy ? "Buy Now" : "Submit Offer"}
                        </SecondaryButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                    </Grid>
                  )}
                  <Grid item xs={12} className="centerStyle">
                    <Button
                      onClick={() => {
                        setOpen(false);
                        props.handleClose();
                      }}
                      style={{
                        color: palette.black,
                        textDecorationLine: "underline",
                        fontSize: 12,
                        textTransform: "none",
                        fontFamily: "BentonSans",
                        fontWeight: 500,
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ) : props.types === "processing" ? (
          <Box sx={style}>
            <Box
              style={{
                overflow: "hidden auto",
                maxHeight: "calc(100vh - 100px)",
                padding: "30px",
              }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: 24,
                      color: palette.black,
                      fontFamily: "BentonSans",
                      fontWeight: 500,
                    }}>
                    {props.processingTitle}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: 16,
                      color: palette.black,
                      fontFamily: "BentonSans",
                      fontWeight: 400,
                    }}>
                    {props.processingContent}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="centerStyle">
                  <Image
                    unoptimized={true}
                    src={"/images/loader_sothebys.gif"}
                    height={160}
                    width={160}
                    alt="loading"
                  />
                </Grid>
                {!isEmpty(props?.transactionHash) &&
                  props.processingContent.includes(
                    "Transaction in progress"
                  ) && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        paddingTop: "16px",
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <Typography
                        sx={{
                          fontFamily: "BentonSans",
                          fontWeight: 500,
                          fontSize: "12px",
                          textAlign: "center",
                          color: "#000000",
                        }}>
                        {"Transaction ID"}
                        <br />
                        <span
                          style={{
                            textDecoration: "underline",
                            fontWeight: 400,
                            color: "#042439",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              `${chainExplorer.url}/tx/${props.transactionHash}`
                            );
                          }}>
                          {truncateAddress(props?.transactionHash)}
                        </span>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Box>
        ) : props.types === "success" || props.types === "submitted" ? (
          <Box sx={style}>
            <Box
              style={{
                overflow: "hidden auto",
                maxHeight: "calc(100vh - 100px)",
                padding: "30px",
              }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    margin: "32px 0px 32px 0px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}>
                  <CheckCircleOutlineIcon
                    sx={{ color: palette.success, fontSize: 120 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" className="successText">
                    {props.types === "success"
                      ? "Payment Success!"
                      : props.isSwap
                      ? `Swap Success!`
                      : "Offer Submitted!"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    className="successText"
                    sx={
                      props.types === "success" ? { marginBottom: "25px" } : {}
                    }>
                    {props.isSwap && props.types === "success"
                      ? `Your wallet has been successfully swapped with ${
                          props.makeOfferSwapAmount
                        } ${
                          props.connect?.currency === "WETH" ? "ETH" : "MATIC"
                        }`
                      : props.types === "success"
                      ? "Redirecting you to Confirmation Screen..."
                      : "We have notified the Seller of your offer. Once accepted, the item will be delivered to your connected wallet."}
                  </Typography>
                </Grid>

                {props.types === "submitted" && (
                  <>
                    <Grid
                      item
                      xs={12}
                      className="centerStyle"
                      sx={{ marginTop: "40px" }}>
                      <SecondaryButton
                        className="submit-button"
                        buttontype="primary"
                        onClick={handleViewItem}>
                        View Item
                      </SecondaryButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className="centerStyle"
                      sx={{ margin: "18px 0px 64px 0px" }}>
                      <SecondaryButton
                        className="submit-button"
                        buttontype="primary"
                        onClick={handleBackToMarketPlace}>
                        Back to Marketplace
                      </SecondaryButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box sx={style}>
            <Box
              style={{
                overflow: "hidden auto",
                maxHeight: "calc(100vh - 100px)",
              }}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton onClick={handleConfirmClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} className="centerStyle">
                  <Typography className="checkout-header">
                    Purchase Confirmation
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ padding: { sm: 2, xs: 2, md: 5, lg: 5, xl: 5 } }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} className="info">
                    <Typography className="confirm-info">
                      Payment processed. Your purchased item will be delivered
                      to your connected wallet.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5}>
                    <Typography className="confirm-reference">
                      {props.isBuy
                        ? props.selectedCurrencydata?.unit
                        : props.price[1]?.unit}{" "}
                      Payment
                    </Typography>
                    <Box className="details">
                      <Typography className="paymentText">
                        {props.selectedCurrencydata?.marketPlaceContractAddress?.substring(
                          0,
                          10
                        )}
                        ...
                        {props.selectedCurrencydata?.marketPlaceContractAddress?.substring(
                          props.selectedCurrencydata?.marketPlaceContractAddress
                            ?.length - 4
                        )}
                      </Typography>
                      <Box onClick={() => handleCopyAddress("weth")}>
                        <Image
                          style={{ marginLeft: 10 }}
                          src={"/images/checkout/file.png"}
                          height={15}
                          width={13}
                          alt="file"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={1} xl={1} />
                  <Grid item xs={12} sm={12} md={12} lg={5.5} xl={5.5}>
                    <Typography className="confirm-reference">
                      Reference No.
                    </Typography>
                    <Box className="details">
                      <Typography className="paymentText">
                        {props.orderId?.substring(0, 10)}...
                        {props.orderId?.substring(props.orderId?.length - 4)}
                      </Typography>
                      <Box onClick={() => handleCopyAddress("ref")}>
                        <Image
                          style={{ marginLeft: 10 }}
                          src={"/images/checkout/file.png"}
                          height={15}
                          width={13}
                          alt="file"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="etherScanContainer">
                      <Typography className="paymentText">
                        Transaction Hash:
                      </Typography>
                      <Typography
                        className="paymentText"
                        sx={{ marginBottom: 1 }}>
                        {props.transactionHash}
                      </Typography>
                      <a
                        href={`${chainExplorer.url}/tx/${props.transactionHash}`}
                        target="_blank"
                        rel="noreferrer">
                        <Box className="viewOnEtherscan">
                          <Image
                            style={{ marginLeft: 10 }}
                            src={"/images/checkout/eye.png"}
                            height={8}
                            width={13}
                            alt="file"
                          />
                          <Typography
                            style={{
                              fontSize: 12,
                              color: "#686868",
                              marginLeft: 10,
                              fontFamily: "BentonSans",
                            }}>
                            View on Etherscan
                          </Typography>
                        </Box>
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography
                      className="textStyle"
                      sx={{ marginTop: 0.5, fontWeight: 600 }}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    sx={{
                      justifyContent: {
                        lg: "flex-end",
                        xl: "flex-end",
                        md: "flex-end",
                        sm: "flex-start",
                        xs: "flex:start",
                      },
                      alignItems: {
                        lg: "flex-end",
                        xl: "flex-end",
                        md: "flex-end",
                        sm: "flex-start",
                        xs: "flex:start",
                      },
                      display: "flex",
                    }}>
                    <Typography
                      sx={{
                        fontSize: 16,
                        color: palette.success,
                        fontFamily: "BentonSans",
                        marginTop: { sm: 2, xs: 2, md: 0, lg: 0, xl: 0 },
                      }}>
                      {`${truncateDecimal(totalTaxEthOrMatic, 4)} ${
                        props.selectedCurrencydata?.unit
                      }`}{" "}
                      {`(${
                        formatNumberToUSD(
                          props.latestPrice?.[0]?.value + usdTax,
                          4
                        ) +
                        " " +
                        "USD"
                      })`}
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  className="divider"
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
                <Box>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography
                        className="textStyle"
                        sx={{
                          marginBottom: 2,
                          fontWeight: 600,
                        }}>
                        Purchase Summary
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <MediaImageComponent
                        mediaImage={props.NFTDetailsState?.mediaImage}
                        mediaExtension={props.NFTDetailsState?.mediaExtension}
                        image={props.NFTDetailsState?.image}
                        animationURL={props.NFTDetailsState?.animationURL ?? ""}
                        secondImage={""}
                        dynamicSize={{ height: "80px", width: "80px" }}
                        autoPlay={false}
                        videoSize={{ height: "80px", width: "80px" }}
                      />
                    </Grid>
                    <Grid item xs={9} sx={{}}>
                      <Box style={{ height: 80, padding: 5 }}>
                        <Typography
                          className="textStyle"
                          sx={{ marginBottom: 4, fontWeight: 600 }}>
                          {props.NFTDetailsState?.name?.length > 0
                            ? props?.NFTDetailsState?.name
                            : "-"}
                        </Typography>
                        <Typography
                          className="textStyle"
                          sx={{ fontWeight: 400 }}>
                          Quantity: {props.quantity}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className="divider"
                  sx={{ marginTop: 1, marginBottom: 4 }}
                />
                <Grid container style={{ marginBottom: 10 }}>
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={10}
                    xl={10}
                    className="centerStyle">
                    <SecondaryButton
                      className="submit-button view-item"
                      buttontype="primary"
                      onClick={handleViewItem}>
                      View Item
                    </SecondaryButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                </Grid>
                <Grid container style={{}}>
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={10}
                    lg={10}
                    xl={10}
                    className="centerStyle">
                    <SecondaryButton
                      className="submit-button"
                      buttontype="noOutline"
                      onClick={handleBackToMarketPlace}>
                      Back to Marketplace
                    </SecondaryButton>
                  </Grid>
                  <ToastComponent
                    type={"success"}
                    message={"copied to clipboard"}
                    open={open}
                    handleClose={handleClose}
                  />
                  <Grid item xs={12} sm={12} md={1} lg={1} xl={1} />
                </Grid>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
})`
  .submit-button {
    font-weight: 500;
  }
  .view-item {
    margin-bottom: 10px;
  }
  .billing {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .address {
      text-align: end;
    }
  }
  .confirm-reference {
    font-size: 12px;
    color: ${palette.black};
    font-family: IBM Plex Sans;
    font-weight: 600;
  }
  .info {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #eafee9;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid ${palette?.primaryGreen};
  }
  .viewOnEtherscan {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 6px;
    background-color: ${palette?.mildGray};
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid ${palette?.mildGray};
    width: 145px;
  }
  .paymentText {
    font-size: 12px;
    color: #292929;
    font-family: BentonSans;
  }
  .details {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f8f8;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid ${palette?.mildGray};
  }
  .currencyText {
    font-size: 12px;
    font-family: BentonSans;
    font-weight: 600;
  }
  .successText {
    font-size: 14px;
    color: ${palette.black};
    font-family: BentonSans;
  }
  .textStyle {
    font-size: 12px;
    color: ${palette.black};
    font-family: BentonSans;
  }
  .text-overlap-style {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .taxStyle {
    font-size: 12px;
    color: #9e9e9e;
    font-family: BentonSans;
  }
  .etherScanContainer {
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f8f8;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid ${palette?.mildGray};
    word-wrap: break-word;
  }
  .checkout-header {
    font-size: 24px;
    font-family: Mercury Text G1;
    font-weight: 500;
  }
  .confirm-info {
    font-size: 12px;
    color: ${palette.primaryGreen};
    font-family: BentonSans;
    font-weight: 400;
  }
  .walletAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .balance {
    font-size: 12px;
    color: #9e9e9e;
    font-family: BentonSans;
    font-weight: 600;
  }
  .underlineStyle {
    font-size: 12px;
    color: ${palette.warning};
    text-decoration-line: underline;
    margin-left: 3px;
    cursor: pointer;
    font-family: BentonSans;
  }
  .warningText {
    font-size: 12px;
    color: ${palette.warning};
    font-family: BentonSans;
  }
  .warningContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: ${palette.warningBackground};
    margin-bottom: 10px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid ${palette?.warning};
  }
  .connectButton {
    border-color: ${palette.success};
    color: ${palette.success};
    background-color: ${palette.successBackground};
    border-radius: 5px;
    height: 30px;
    width: 85px;
    font-size: 12px;
    text-transform: capitalize;
    font-family: BentonSans;
    font-weight: 400;
  }
  .checkout-container {
    background-color: #f8f8f8;
    padding: 16px;
    border: 1px solid ${palette?.mildGray};
    border-radius: 5px;
  }
  .centerStyle {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .endStyle {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
  }
  .divider {
    height: 1.2px;
    background-color: ${palette?.mildGray};
    width: 100%;
  }
`;

export default Checkout;
