import React, { useState } from "react";
import SVG from "react-inlinesvg";
import Image from "next/image";
import {
  isEmpty,
  defaultimg,
  GetIpfsImage,
  getValidImageUrl,
  isImage,
  isVideo,
  getCloudinaryImage,
} from "@utils/wallet/numberFormet.util";
import VideoComponent from "./videoComponent";
import { LAZY_LOAD_IMAGE_BLUR } from "@utils/lazyLoadImageBlur";

interface IimageComponent {
  image: string;
  secondImage?: string;
  width?: string;
  height?: string;
  autoPlay?: boolean;
  mute?: boolean;
  alt?: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive" | undefined;
  objectFit?: "contain" | undefined;
  page?: string;
  handleImagesize?: (height: number, width: number) => void;
}

const ImageComponent = ({
  image,
  secondImage,
  width = "100%",
  height = "100%",
  autoPlay = true,
  mute = true,
  alt = "NFT image",
  layout = undefined,
  objectFit = undefined,
  page = "CONTENTFULL",
  handleImagesize,
}: IimageComponent): JSX.Element => {
  // If image got error try in IFrame
  const [imageError, setImageError] = useState("IMAGE_1");

  const getImageVideoComponent = () => {
    if (isVideo(image) || imageError === "VIDEO") {
      return (
        <VideoComponent
          animationURL={GetIpfsImage(image, "IMAGE")}
          width={width}
          height={height}
          autoPlay={autoPlay}
          mute={mute}
        />
      );
    } else if (image.includes("<svg")) {
      const svgPrefix = image.includes("data:image/svg+xml;utf8,")
        ? "data:image/svg+xml;utf8,"
        : "data:image/svg+xml,";
      const correctPrefix =
        svgPrefix === "data:image/svg+xml;utf8,"
          ? "data:image/svg+xml;charset=UTF-8,"
          : "data:image/svg+xml,";

      const svgContent = image.replace(svgPrefix, "");
      const processedImage = correctPrefix + encodeURIComponent(svgContent);

      return <SVG src={processedImage} />;
    } else if (imageError === "IMAGE_1") {
      return (
        <Image
          width={width}
          height={height}
          onError={(e: any) => {
            setImageError(!isEmpty(secondImage) ? "IMAGE_2" : "VIDEO");
            defaultimg(e);
          }}
          src={
            image?.includes("base64")
              ? image
              : isEmpty(image) || !getValidImageUrl(image)
              ? "/images/image_not_found.png"
              : getCloudinaryImage(GetIpfsImage(image, "DETAILS"))
          }
          layout={layout}
          objectFit={objectFit}
          blurDataURL={LAZY_LOAD_IMAGE_BLUR}
          placeholder="blur"
          alt={alt}
          onLoadingComplete={(e: any) => {
            handleImagesize?.(e?.naturalHeight, e?.naturalWidth);
          }}
        />
      );
    } else if (imageError === "IMAGE_2" || isImage(image)) {
      return (
        <Image
          width={width}
          height={height}
          onError={(e: any) => {
            setImageError("VIDEO");
            defaultimg(e);
          }}
          src={
            !isEmpty(secondImage)
              ? getCloudinaryImage(GetIpfsImage(secondImage ?? "", "DETAILS"))
              : "/images/image_not_found.png"
          }
          layout={layout}
          objectFit={objectFit}
          blurDataURL={LAZY_LOAD_IMAGE_BLUR}
          placeholder="blur"
          alt={alt}
          onLoadingComplete={(e: any) => {
            handleImagesize?.(e?.naturalHeight, e?.naturalWidth);
          }}
        />
      );
    }
  };

  return <>{getImageVideoComponent()}</>;
};

export default ImageComponent;
