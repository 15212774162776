import React, { useState } from "react";
import styled from "styled-components";
import Image from "next/image";

interface IframeComponentProps {
  src: string;
  width?: number | string;
  height?: number | string;
  title?: string;
}

const IframeContainer = styled.div<{
  width: number | string;
  height: number | string;
}>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  zindex: 10;
`;

const IframeComponent: React.FC<IframeComponentProps> = ({
  src,
  width = "100%",
  height = "100%",
  title,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(true);

  return (
    <IframeContainer width={width} height={height}>
      <iframe
        src={src}
        width={width}
        height={height}
        title={title}
        className="iframe-style"
        onLoad={() => setLoading(false)}
        {...rest}
      />
      {isLoading && (
        <LoadingOverlay>
          <Image
            src={"/images/loader_sothebys.gif"}
            height={80}
            width={80}
            unoptimized={true}
            alt="loading"
          />
        </LoadingOverlay>
      )}
    </IframeContainer>
  );
};

export default IframeComponent;
